import { getCookieValue } from "./getCookieValue";

interface CustomerDataArgs {
  initialAmount: number;
  postgres_plan_id: string;
  tier_type: number;
  price: string;
}

export const getCustomerData = (additionalParams?: CustomerDataArgs) => {
  const params = new URLSearchParams(window.location.search);
  const extractedParams = {} as any;

  params.forEach((value, key) => {
    extractedParams[key] = value;
  });

  const metadata: any = {
    funnel: extractedParams.funnel || "strp",
    email: decodeURIComponent(extractedParams.email),
    first_name:
      extractedParams.first_name || extractedParams.name?.split(" ")[0],
    last_name:
      extractedParams.last_name || extractedParams.name?.split(" ")[1] || "_",
    name:
      extractedParams.name ||
      `${extractedParams.first_name} ${extractedParams.last_name || "_"}`,
    initialAmount:
      additionalParams?.initialAmount || extractedParams.initialAmount || 0,
    postgres_plan_id:
      additionalParams?.postgres_plan_id || extractedParams.postgres_plan_id,
    price: additionalParams?.price || extractedParams.price,
    tier_type: additionalParams?.tier_type || extractedParams.tier_type,
    utm_medium: extractedParams.utm_medium || "NA",
    utm_source: extractedParams.utm_source || "NA",
    utm_content: extractedParams.utm_content || "NA",
    utm_campaign: extractedParams.utm_campaign || "NA",
    funnelid: extractedParams.funnelid || "tiers-v1",
    phone: extractedParams.phone || "NA",
    vwo_uuid: getCookieValue("_vwo_uuid"),
  };

  const optionalParams = [
    "uuid",
    "order_id",
    "rfsn",
    "rfsn_v4_id",
    "rfsn_v4_aid",
    "rfsn_v4_cs",
    "vwo_uuid",
    "fbclid",
    "gclid",
    "ttclid",
    "funnel_mode",
    "funnel_start",
    "mode",
    "matching",
  ];

  optionalParams.forEach((param) => {
    if (extractedParams[param] !== undefined) {
      metadata[param] = extractedParams[param];
    }
  });

  return metadata;
};

export const getPriceFromQuery = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("price") || "";
};

export const getNewBillingCycleFromQuery = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const billingCycle = queryParams.get("billing_cycle");

  return billingCycle === "true";
};
